import React, { useState, FormEvent } from 'react';
import { Box, Tabs, Tab, Alert, Snackbar } from '@mui/material';
import { addItem, DEFAULT_ITEM, updateItem } from '../../utils';
import TabPanel from '../TabPanel/index';
import ItemMenu from '../ItemMenu/index';
import { IItemMenu } from '../../@types/dashboard';
import SelectItemMenu from '../SelectItemMenu';

interface IFormMenuProps {
  doc_name: string[]; // Lista dei piatti o dei vini
  colName: string; // menu o vini
  tipoItem: 'PIATTI' | 'VINI';
}

const FormMenu: React.FC<IFormMenuProps> = ({ doc_name, colName, tipoItem }) => {
  const [currentPanel, setCurrentPanel] = useState(0);
  const [itemData, setItemData] = useState<IItemMenu>(DEFAULT_ITEM);
  const [selected, setSelected] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveSuccess, setIsSaveSuccess] = useState(false);
  const [isSaveError, setIsSaveError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const handleChangePanel = async (event: React.SyntheticEvent, panel: number) => {
    try {
      setCurrentPanel(panel);
      setItemData(DEFAULT_ITEM);
      setSelected('');
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  const handleChangeItemInput = (event: FormEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = event.currentTarget;
    setItemData((prevState) => ({ ...prevState, [name]: type === 'checkbox' ? checked : value }));
  };

  const handleReset = () => {
    setItemData(DEFAULT_ITEM);
    setSelected('');
  };

  const handleSubmit = async () => {
    const { id, price, ...data } = itemData;

    if (id) {
      try {
        await updateItem(colName, doc_name[currentPanel], id, { price: price * 10, ...data });
      } catch (error) {
        setErrorMsg((error as Error).message);
        setIsSaveError(true);
      }
    } else {
      try {
        addItem(colName, doc_name[currentPanel], { price: price * 10, ...data });
      } catch (error) {
        setErrorMsg((error as Error).message);
        setIsSaveError(true);
      }
    }
    setSelected('');
    setItemData(DEFAULT_ITEM);
    setIsSaveSuccess(true);
  };

  return (
    <>
      <Snackbar open={isSaveSuccess} autoHideDuration={2000} onClose={() => setIsSaveSuccess(false)}>
        <Alert onClose={() => setIsSaveSuccess(false)} severity='success' sx={{ width: '100%' }}>
          Dati salvati.
        </Alert>
      </Snackbar>

      <Snackbar open={isSaveError} onClose={() => setIsSaveError(false)}>
        <Alert onClose={() => setIsSaveError(false)} severity='error' sx={{ width: '100%' }}>
          {errorMsg}
        </Alert>
      </Snackbar>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2 }}>
        <Tabs value={currentPanel} onChange={handleChangePanel}>
          {doc_name.map((item) => (
            <Tab label={item} key={item} />
          ))}
        </Tabs>
      </Box>
      <Box>
        {doc_name.map((item, i) => (
          <TabPanel key={item} value={currentPanel} index={i}>
            <SelectItemMenu
              colName={colName}
              docName={doc_name[currentPanel]}
              setItemData={setItemData}
              setSelected={setSelected}
              selected={selected}
              setIsLoading={setIsLoading}
            />
            <ItemMenu
              {...itemData}
              handleChange={handleChangeItemInput}
              selectItem={selected}
              handleReset={handleReset}
              indexPanel={i}
              loading={isLoading}
              handleSubmit={handleSubmit}
            />
          </TabPanel>
        ))}
      </Box>
    </>
  );
};

export default FormMenu;
