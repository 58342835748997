import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Slide,
  IconButton,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { ReactElement, useState } from 'react';

interface IConfirmDialog {
  buttonText?: string;
  color?: 'inherit' | 'error' | 'success' | 'info' | 'warning' | 'primary' | 'secondary' | undefined;
  contentText: string;
  disabled?: boolean;
  icon?: ReactElement;
  onConfirm: () => void;
  size?: 'small' | 'large' | 'medium' | undefined;
  title: string;
}

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) => {
    return <Slide direction='up' ref={ref} {...props} />;
  }
);

const ConfirmDialog: React.FC<IConfirmDialog> = ({
  buttonText,
  color = 'primary',
  contentText,
  disabled = false,
  icon,
  onConfirm,
  title,
  size,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const OpenButton = () => {
    if (icon) {
      return (
        <IconButton onClick={handleClickOpen} color={color} size={size} disabled={disabled}>
          {icon}
        </IconButton>
      );
    }
    return (
      <Button variant='outlined' onClick={handleClickOpen} size='large' color={color} fullWidth disabled={disabled}>
        {buttonText || 'Alert dialog'}
      </Button>
    );
  };

  return (
    <>
      {OpenButton()}
      <Dialog open={open} keepMounted onClose={handleClose} TransitionComponent={Transition}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{contentText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annulla</Button>
          <Button
            onClick={() => {
              handleClose();
              onConfirm();
            }}
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmDialog;
