import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Toolbar,
  Typography,
} from '@mui/material';
import { CSSObject, styled, Theme, useTheme } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useEffect, useRef, useState } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Link, Outlet } from 'react-router-dom';
import { pages } from '../../pages';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LogoutIcon from '@mui/icons-material/Logout';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { ThemeContext } from '../../utils/theme';
import { logOut } from '../../utils';
import { query, collection, where } from 'firebase/firestore';
import { db, auth } from '../../firebase/firebase-config';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import KeyIcon from '@mui/icons-material/Key';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Navbar = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openOption, setOpenOption] = useState(false);
  const [name, setName] = useState('');
  const [navHeight, setHeight] = useState(0);
  const headerRef = useRef<HTMLHeadingElement>(null);
  const optionRef = useRef<HTMLHeadingElement>(null);
  const colorMode = React.useContext(ThemeContext);
  const [user] = useAuthState(auth);
  const [dataUser] = useCollectionData(query(collection(db, 'users'), where('uid', '==', user ? user?.uid : '')), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const handleToggleDrawer = () => {
    setOpen((prevState) => !prevState);
  };

  const handleToggleOption = () => {
    setOpenOption((prevState) => !prevState);
  };

  const handleClosePopper = (event: Event) => {
    if (optionRef.current && optionRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpenOption(false);
  };

  useEffect(() => {
    if (headerRef && headerRef.current) {
      setHeight(headerRef.current.clientHeight);
    }
    if (dataUser?.length) {
      setName(dataUser[0].name);
    }
  }, [headerRef, dataUser]);

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <AppBar position='fixed' open={open} ref={headerRef}>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleToggleDrawer}
            edge='start'
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' noWrap component='div' sx={{ flexGrow: 1 }}>
            Casa Barbabuc
          </Typography>
          <Box ref={optionRef}>
            <Button onClick={handleToggleOption} variant='contained'>
              Hello {name}
              <ArrowDropDownIcon />
            </Button>
            <Popper open={openOption} anchorEl={optionRef.current} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClosePopper}>
                      <Box sx={{ p: 1, bgcolor: 'background.paper', display: 'grid', gridGap: 10 }}>
                        <Button variant='contained' onClick={logOut}>
                          <Link to='/'>
                            <LogoutIcon />
                          </Link>
                        </Button>
                        <Button onClick={colorMode.toggleColorMode} variant='contained'>
                          {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                        </Button>
                        <Button variant='contained'>
                          <Link to='password'>
                            <KeyIcon />
                          </Link>
                        </Button>
                      </Box>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Drawer */}
      <Drawer variant='permanent' open={open} sx={{ display: { xs: open ? 'block' : 'none', sm: 'block' } }}>
        <DrawerHeader>
          <IconButton onClick={handleToggleDrawer}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {pages.map(({ path, icon }) => (
            <ListItem key={path}>
              <Link
                onClick={() => {
                  setOpen(false);
                }}
                to={path}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText
                  primary={path}
                  sx={{ color: 'text.primary', opacity: open ? 1 : 0, textTransform: 'uppercase' }}
                />
              </Link>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Outlet context={{ navHeight }} />
    </Box>
  );
};

export default Navbar;
