import React, { useEffect, useState } from 'react';
import {
  Container,
  Alert,
  AlertTitle,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Toolbar,
  Tooltip,
  IconButton,
  Badge,
  Box,
  Typography,
} from '@mui/material';
import HeaderPage from '../../components/HeaderPage/index';
import { db, storage } from '../../firebase/firebase-config';
import CollectionsIcon from '@mui/icons-material/Collections';
import Loading from '../../components/Loading';
import FilterListIcon from '@mui/icons-material/FilterList';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  collection,
  deleteDoc,
  doc,
  DocumentData,
  FirestoreDataConverter,
  FirestoreError,
  query,
  QueryDocumentSnapshot,
  SnapshotOptions,
  where,
} from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import TableFilter from '../../components/TableFilter';
import UploadFile from '../../components/UploadFile';
import { deleteObject, ref, StorageError } from 'firebase/storage';
import Error from '../../components/Error';
import ConfirmDialog from '../../components/ConfirmDialog';

export const LIST_DIR = ['camere', 'bottega', 'locali', 'logo', 'piatti', 'staff'];
const ROWS_PER_PAGE = 3;

type Image = {
  cartella: string;
  url: string;
  id: string;
};

const imageConverter: FirestoreDataConverter<Image> = {
  toFirestore({ url }): DocumentData {
    return { url };
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): Image {
    const { id } = snapshot;
    const { url, cartella } = snapshot.data(options)!;
    return {
      id,
      url,
      cartella,
    };
  },
};

const GalleryPage = () => {
  const [images, setImages] = useState<Image[]>([]);
  const [page, setPage] = useState(0);
  const [filterEl, setFilterEl] = useState<null | HTMLElement>(null);
  const [filter, setFilter] = useState('');
  const [errorDelete, setErrorDelete] = useState('');

  const colImmagini = collection(db, 'immagini');
  const q = filter ? query(colImmagini, where('cartella', '==', filter)) : query(colImmagini);
  const [values, loading, error] = useCollectionData(q.withConverter(imageConverter));

  useEffect(() => {
    if (values !== undefined) {
      setImages(values);
    }
  }, [values]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleClickFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    const tableHead = document.getElementById('table-id');
    setFilterEl(filterEl ? null : tableHead);
  };

  const deleteImage = async (id: string, cartella: string) => {
    setErrorDelete('');
    if (page * ROWS_PER_PAGE === images.length - 1 && page > 0) {
      setPage((prevPage) => prevPage - 1);
    }
    await deleteDoc(doc(db, 'immagini', id));
    const imageRef = ref(storage, `img/${cartella}/${id}`);
    deleteObject(imageRef)
      .then(async () => {
        try {
          await deleteDoc(doc(db, 'immagini', id));
        } catch (error) {
          setErrorDelete((error as FirestoreError).message);
          console.log('Error firestore:>> ', error);
        }
      })
      .catch((error) => {
        setErrorDelete((error as StorageError).message);
        console.log('Error storage:>> ', error);
      });
  };

  return (
    <Container>
      <HeaderPage marginTop={24}>
        Gallery <CollectionsIcon />
      </HeaderPage>

      {error && (
        <Alert severity='error' sx={{ marginBottom: 2 }}>
          <AlertTitle>Error</AlertTitle>
          {JSON.stringify(error)}
        </Alert>
      )}
      {Boolean(errorDelete) && <Error title='Error' errorMsg={errorDelete} />}
      <UploadFile />
      <TableContainer component={Paper}>
        <Toolbar>
          <Tooltip title='Filter list' placement='top'>
            <Box display={'inline-flex'} alignItems='center'>
              <IconButton onClick={handleClickFilter}>
                <Badge color='success' variant='dot' invisible={!Boolean(filter)}>
                  <FilterListIcon />
                </Badge>
              </IconButton>
              <Typography variant='body2' ml={2}>
                {filter}
              </Typography>
            </Box>
          </Tooltip>
          <TableFilter
            anchorEl={filterEl}
            filterString={LIST_DIR}
            filter={filter}
            setFilter={setFilter}
            setAnchorEl={setFilterEl}
          />
        </Toolbar>
        <Table>
          <TableHead id='table-id'>
            <TableRow>
              <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>Nome</TableCell>
              <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>Cartella</TableCell>
              <TableCell>Immagine</TableCell>
              <TableCell>Elimina</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {images
              .slice(page * ROWS_PER_PAGE, page * ROWS_PER_PAGE + ROWS_PER_PAGE)
              .map(({ cartella, url, id }, i) => (
                <TableRow key={i}>
                  <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{id}</TableCell>
                  <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>{`${cartella}`}</TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        width: '200px',
                        height: '200px',
                        padding: '0',
                      }}
                    >
                      <a
                        href={url}
                        rel='noreferrer'
                        target='_blank'
                        style={{
                          backgroundImage: `url(${url})`,
                          backgroundPosition: 'center center',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          display: 'block',
                          height: '100%',
                          color: 'transparent',
                        }}
                      >
                        link
                      </a>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <ConfirmDialog
                      icon={<DeleteIcon />}
                      color='error'
                      title='Elimina'
                      contentText='Vuoi eliminare la foto?'
                      onConfirm={() => deleteImage(id, cartella)}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[]}
                count={images.length}
                onPageChange={handleChangePage}
                page={page}
                rowsPerPage={ROWS_PER_PAGE}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      {loading && <Loading />}
    </Container>
  );
};

export default GalleryPage;
