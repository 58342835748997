import React, { createContext, useContext, useState } from 'react';
import { IUser, IAppContext } from '../@types/dashboard.d';

interface Props {
  children: React.ReactNode;
}

const DEFAULT_USER: IUser = {
  accessToken: '',
  email: '',
  uid: '',
};

export const AppContext = createContext<IAppContext | null>(null);

const AppProvaider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<IUser>(DEFAULT_USER);

  const saveUser = (user: IUser) => {
    setUser(user);
  };

  return <AppContext.Provider value={{ user, saveUser }}>{children}</AppContext.Provider>;
};

export const useGlobalContext = () => {
  return useContext(AppContext) as IAppContext;
};

export default AppProvaider;
