import { HomePage, LoginPage, ErrorPage, OrariPage, MenuPage, ViniPage, DeletePage, ContactsPage } from '../pages';
import { MainLayout } from '../layouts';
import PasswordPage from '../pages/Password/index';
import GalleryPage from '../pages/Gallery';

export const routes = [
  {
    element: <MainLayout />,
    children: [
      { path: 'home', element: <HomePage /> },
      { path: 'contatti', element: <ContactsPage /> },
      { path: 'orari', element: <OrariPage /> },
      { path: 'menu', element: <MenuPage /> },
      { path: 'vini', element: <ViniPage /> },
      { path: 'delete', element: <DeletePage /> },
      { path: 'password', element: <PasswordPage /> },
      { path: 'gallery', element: <GalleryPage /> },
    ],
  },
  {
    children: [
      { path: '/', element: <LoginPage /> },
      { path: '/login', element: <LoginPage /> },
    ],
  },
  {
    path: '*',
    element: <ErrorPage />,
  },
];
