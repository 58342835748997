import React, { useEffect, useRef, useState } from 'react';
import { IFileUploader } from '../../@types/dashboard';
import { Button, List, ListItem, ListItemText, SelectChangeEvent, Box } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useUploadFile } from 'react-firebase-hooks/storage';
import { db, storage } from '../../firebase/firebase-config';
import { ref, getDownloadURL } from 'firebase/storage';
import Error from '../Error';
import Loading from '../Loading';
import SelectSection from '../SelectSection';
import { LIST_DIR } from '../../pages/Gallery/index';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import { setDoc, doc, FirestoreError } from 'firebase/firestore';

const FileUploader: React.FC<IFileUploader> = ({ setListUpload, listUpload, displayListNames }) => {
  const [listNames, setListNames] = useState<string[]>([]);
  const hiddenFileInput = useRef<null | HTMLInputElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    hiddenFileInput.current?.click();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const list: string[] = [];
    [].forEach.call(event.target.files, (file: File) => {
      list.push(file.name);
    });
    setListNames(list);
    setListUpload(event.target.files);
  };

  useEffect(() => {
    if (!Boolean(listUpload?.length)) {
      const input = document.getElementById('listFile') as HTMLInputElement;
      input.value = '';
      setListNames([]);
    }
  }, [listUpload]);

  return (
    <>
      <Button onClick={handleClick} startIcon={<CloudUploadIcon />} style={{ maxWidth: '100px' }}>
        upload
      </Button>

      {displayListNames && (
        <List dense>
          {listNames.map((name) => (
            <ListItem key={name}>
              <ListItemText primary={name} />
            </ListItem>
          ))}
        </List>
      )}

      <input
        id='listFile'
        onChange={handleChange}
        type='file'
        accept='image/x-png,image/jpeg'
        multiple
        hidden
        ref={hiddenFileInput}
      />
    </>
  );
};

const UploadFile = () => {
  const [listUpload, setListUpload] = useState<FileList | null>(null);
  const [section, setSection] = useState('');
  const [isError, setIsError] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [uploadFile, uploading, snapshot, error] = useUploadFile();

  const handleChange = (event: SelectChangeEvent) => {
    setSection(event.target.value);
  };

  const handleClickSend = (event: React.MouseEvent<HTMLButtonElement>) => {
    [].forEach.call(listUpload, async (file: File) => {
      const { name, type: contentType } = file;
      const fileName = `img/${section}/${name}`;
      const refImg = ref(storage, fileName);
      const result = await uploadFile(refImg, file, { contentType });
      // se il file viene caricato ottengo l'url e aggiorno il db
      if (result) {
        try {
          const url = await getDownloadURL(result?.ref);
          if (url) {
            try {
              await setDoc(doc(db, `immagini`, name), { cartella: section, url });
            } catch (error) {
              setIsError((error as FirestoreError).message);
            }
          } else {
            setIsError('getDownloadURL error');
          }
        } catch (error) {
          setIsError((error as FirestoreError).message);
        }
      }
    });

    setListUpload(null);
    setSection('');
  };

  const handleClickCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    setListUpload(null);
    setSection('');
  };

  return (
    <Box display={'inline-flex'} flexDirection={'column'} gap={2} mb={2}>
      <FileUploader setListUpload={setListUpload} listUpload={listUpload} displayListNames />
      {error && <Error title='Error' errorMsg={error.message} />}
      {isError && <Error title='Error' errorMsg={isError} />}
      {uploading && <Loading />}
      {listUpload && listUpload?.length > 0 && (
        <>
          <SelectSection label='Cartella' selected={section} listItem={LIST_DIR} handleChange={handleChange} />

          <Box>
            <Button
              onClick={handleClickSend}
              startIcon={<SendIcon />}
              disabled={!Boolean(section) && Boolean(listUpload)}
            >
              send
            </Button>
            <Button onClick={handleClickCancel} color='secondary' startIcon={<CancelIcon />}>
              cancel
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default UploadFile;
