import { Button, IconButton, List, ListItem, ListItemText, Paper, Tooltip, Typography, Alert } from '@mui/material';
import React, { useState, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { addItem, deleteItem, IContact, updateItem, useDocWithId } from '../../utils';
import ContactDialog from '../ContactDialog/index';
import Loading from '../Loading';
import AddIcon from '@mui/icons-material/Add';
import { collection } from 'firebase/firestore';
import { db } from '../../firebase/firebase-config';

interface IListContact {
  contact: 'cellulare' | 'email' | 'fax' | 'telefono' | 'indirizzo';
  addButton?: boolean;
  editButton?: boolean;
  deleteButton?: boolean;
}

const ListContact: React.FC<IListContact> = ({
  contact: contactType,
  addButton = true,
  editButton = true,
  deleteButton = true,
}) => {
  const [contact, setContact] = useState<IContact[]>([]);
  const [indexItem, setIndexItem] = useState(-1);
  const [openDialog, setOpenDialog] = useState(false);

  const [contactData, isLoading, isError] = useDocWithId(collection(db, 'contatti', contactType, 'lista'), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const handleDeleteCellulare = async (event: React.MouseEvent<HTMLElement>) => {
    const { id } = event.currentTarget;
    await deleteItem('contatti', contactType, id);
    if (contactData?.length === 1) {
      setContact([]);
    }
  };

  const handleSaveData = async (id: string, dati: string) => {
    try {
      id ? await updateItem('contatti', contactType, id, { dati }) : await addItem('contatti', contactType, { dati });
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  const handleEditCellulare = (event: React.MouseEvent<HTMLElement>) => {
    const { id } = event.currentTarget;
    setIndexItem(contact.findIndex((item) => item.id === id));
    setOpenDialog(true);
  };

  useEffect(() => {
    if (contactData) {
      setContact(contactData as IContact[]);
    }
  }, [contactData]);

  return isError ? (
    <strong>Error: {JSON.stringify(isError)}</strong>
  ) : isLoading ? (
    <Loading />
  ) : (
    <>
      {addButton && (
        <Button
          onClick={(e) => {
            setIndexItem(-1);
            setOpenDialog(true);
          }}
          variant='outlined'
        >
          <AddIcon />
          Aggiungi
        </Button>
      )}
      {contact.length < 1 ? (
        <Alert severity='warning' sx={{ marginTop: 1 }}>
          Non sono presenti dati
        </Alert>
      ) : (
        <List>
          {contact.map(({ id, dati }) => (
            <Paper key={id} sx={{ marginBottom: 1 }}>
              <ListItem
                secondaryAction={
                  <>
                    {editButton && (
                      <Tooltip title='Modifica' sx={{ display: { xs: 'none', lg: 'inline-block' }, marginRight: -1 }}>
                        <IconButton id={id} onClick={handleEditCellulare} edge='end' aria-label='edit'>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    )}

                    {deleteButton && (
                      <Tooltip title='Elimina'>
                        <IconButton id={id} onClick={handleDeleteCellulare} edge='end' aria-label='delete'>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                }
              >
                <ListItemText
                  primary={
                    <>
                      <Typography variant='h6' component='span'>
                        {'Numero: '}
                      </Typography>
                      <span id={id} onClick={handleEditCellulare}>
                        {dati}
                      </span>
                    </>
                  }
                />
              </ListItem>
            </Paper>
          ))}
        </List>
      )}
      <ContactDialog
        title={contactType.toUpperCase()}
        type={contactType}
        open={openDialog}
        handleClose={() => {
          setOpenDialog(false);
        }}
        handleSaveData={handleSaveData}
        {...contact[indexItem]}
      />
    </>
  );
};

export default ListContact;
