import React, { useState, useEffect } from 'react';
import {
  CssBaseline,
  Avatar,
  Box,
  Paper,
  Container,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { logInWithEmailAndPassword } from '../../utils';
import { useNavigate } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import { auth } from '../../firebase/firebase-config';
import { useAuthState } from 'react-firebase-hooks/auth';
import Error from '../../components/Error';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isError, setIsError] = useState(false);
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsError(false);

    try {
      await logInWithEmailAndPassword(email, password);
    } catch (error) {
      setIsError(true);
    }
  };

  const handleChange = (event: React.ChangeEvent) => {
    const { name, value } = event.target as HTMLInputElement;
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  useEffect(() => {
    if (loading) {
      return;
    }
    if (user) {
      navigate('/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading]);

  const isValidForm = () => {
    return isEmail(email) && password.length >= 5;
  };

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      {!user && (
        <Box
          component={Paper}
          elevation={3}
          sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>

          <Typography component='h1' variant='h5'>
            Sig in
          </Typography>

          <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '90%' }}>
            <TextField
              onChange={handleChange}
              margin='normal'
              required
              type='email'
              fullWidth
              id='email'
              name='email'
              label='Email Address'
              autoComplete='email'
              value={email}
              autoFocus
            />
            <TextField
              onChange={handleChange}
              margin='normal'
              required
              type='password'
              fullWidth
              id='password'
              name='password'
              label='Password Address'
              autoComplete='current-password'
              value={password}
            />

            <FormControlLabel control={<Checkbox value='remember' color='primary' />} label='Remember me' />

            <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }} disabled={!isValidForm()}>
              Sign In
            </Button>
          </Box>
          {isError && <Error title='Login error:' errorMsg='Nome utente e/o password errati.' />}
        </Box>
      )}
    </Container>
  );
};

export default LoginPage;
