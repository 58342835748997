import React, { FormEvent, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup, Grid, TextField, Button } from '@mui/material';
import { useFocus } from '../../utils/index';

interface IProps {
  archiviato: boolean;
  description_en: string;
  description_it: string;
  name_en: string;
  name_it: string;
  price: number;
  selectItem: string;
  indexPanel: number;
  loading: boolean;
  handleChange(event: FormEvent): void;
  handleReset(event: FormEvent): void;
  handleSubmit(): void;
}

const ItemMenu: React.FC<IProps> = ({
  archiviato,
  description_en,
  description_it,
  name_en,
  name_it,
  price,
  selectItem,
  indexPanel,
  loading,
  handleChange,
  handleReset,
  handleSubmit,
}) => {
  const [error, setError] = useState({ name_it: false, name_en: false, price: false });
  const inputFocus = useFocus();

  const isValidName = (name: string) => {
    if (name.length >= 5 && name.length <= 150) {
      return true;
    }
    return false;
  };

  const isValidPrice = (price: number) => {
    return price > 0 ? true : false;
  };

  const isValidForm = () => {
    return isValidName(name_it) && isValidName(name_en) && isValidPrice(price) ? true : false;
  };

  return (
    <Grid container spacing={2} mt={2}>
      <Grid item xs={12} md={6}>
        <input type='number' id='indexVino' value={indexPanel} disabled hidden />
        <TextField
          autoFocus
          ref={inputFocus.ref}
          error={error.name_it}
          helperText={error.name_it ? 'La lunghezza è compresa tra 5 e 150' : ''}
          name={'name_it'}
          label='Nome it:'
          variant='standard'
          value={name_it}
          inputProps={{ maxLength: 150 }}
          onChange={handleChange}
          onBlur={(e) => {
            setError({ ...error, name_it: !isValidName(e.currentTarget.value) });
          }}
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          error={error.name_en}
          helperText={error.name_en ? 'La lunghezza è compresa tra 5 e 150' : ''}
          onChange={handleChange}
          value={name_en}
          inputProps={{ maxLength: 150 }}
          name={'name_en'}
          label='Nome en:'
          variant='standard'
          onBlur={(e) => {
            setError({ ...error, name_en: !isValidName(e.currentTarget.value) });
          }}
          required
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          onChange={handleChange}
          multiline
          maxRows={4}
          value={description_it}
          name={'description_it'}
          label='Descrizione it:'
          variant='standard'
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          onChange={handleChange}
          multiline
          maxRows={4}
          value={description_en}
          name={'description_en'}
          label='Descrizione en:'
          variant='standard'
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          error={error.price}
          helperText={error.price ? 'Il prezzo minimo deve essere maggiore di 0' : ''}
          onChange={handleChange}
          value={price}
          type='number'
          name={'price'}
          label='Prezzo :'
          variant='standard'
          fullWidth
          InputProps={{ inputProps: { min: 1, step: 0.5 } }}
          required
          onBlur={(e) => {
            setError({ ...error, price: !isValidPrice(parseInt(e.currentTarget.value)) });
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox name='archiviato' checked={archiviato} onChange={handleChange} />}
            label='Archiviato'
          />
        </FormGroup>
      </Grid>
      <Grid item xs={4} md={2} lg={1} mr={{ lg: 3 }}>
        <Button
          variant='outlined'
          color='success'
          disabled={!isValidForm() || loading}
          onClick={() => {
            handleSubmit();
            inputFocus.setFocus();
          }}
        >
          {selectItem === '' ? 'AGGIUNGI' : 'SALVA'}
        </Button>
      </Grid>
      <Grid item xs={4} md={2} lg={1}>
        <Button variant='outlined' color='secondary' onClick={handleReset}>
          ANNULLA
        </Button>
      </Grid>
    </Grid>
  );
};

export default ItemMenu;
