import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Container } from '@mui/material';
import HeaderPage from '../../components/HeaderPage';
import FormDelete from '../../components/FormDelete';

const DeletePage = () => {
  return (
    <Container>
      <HeaderPage marginTop={24}>
        Elimina <DeleteIcon />
      </HeaderPage>

      <FormDelete />
    </Container>
  );
};

export default DeletePage;
