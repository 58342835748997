import React, { useState } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListSubheader,
  SelectChangeEvent,
  Snackbar,
  Alert,
} from '@mui/material';
import { PIATTI, VINI } from '../../utils';
import ListItemMenu from '../ListItemMenu/index';

const DOC_NAME = [...PIATTI, 'sep', ...VINI];

/**
 * Verifica se il doc name passato è nell'array PIATTI in caso affermativo
 * ritorna menu se no vini
 * @param docName string Nome del documento
 * @returns string 'menu' || 'vini'
 */
const getColName = (docName: string) => {
  return ~PIATTI.indexOf(docName) ? 'menu' : 'vini';
};

const FormDelete = () => {
  const [selectItem, setSelectItem] = useState('');
  const [isDeletedSucces, setIsDeletedSucces] = useState(false);

  const handleChangeSelectItem = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setSelectItem(value);
  };

  return (
    <>
      <Box>
        <Snackbar open={isDeletedSucces} autoHideDuration={2000} onClose={() => setIsDeletedSucces(false)}>
          <Alert onClose={() => setIsDeletedSucces(false)} severity='success' sx={{ width: '100%' }}>
            Dati eliminati.
          </Alert>
        </Snackbar>
        <FormControl fullWidth>
          <InputLabel id='select-item'>Seleziona</InputLabel>
          <Select
            labelId='select-item'
            id='select-item-menu'
            value={selectItem}
            label='Seleziona'
            onChange={handleChangeSelectItem}
          >
            <ListSubheader>PIATTI</ListSubheader>
            {DOC_NAME.map((item, i) =>
              i === PIATTI.length ? (
                <ListSubheader key={item}>VINI</ListSubheader>
              ) : (
                <MenuItem key={item} value={item} sx={{ paddingLeft: '30px' }}>
                  {item}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </Box>

      <Box>
        {selectItem && (
          <ListItemMenu
            setSelectItem={setSelectItem}
            colName={getColName(selectItem)}
            docName={selectItem}
            setIsDeletedSucces={setIsDeletedSucces}
          />
        )}
      </Box>
    </>
  );
};

export default FormDelete;
