import React from 'react';
import { ISelectSection } from '../../@types/dashboard';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const SelectSection: React.FC<ISelectSection> = ({ selected, listItem, label, handleChange }) => {
  const id = label ? label : 'Filter';
  return (
    <Box>
      <FormControl variant='standard' sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id={`${id}-standard-label`}>{id}</InputLabel>
        <Select
          labelId={`${id}-standard-label`}
          id={`${id}-standard`}
          value={selected}
          onChange={handleChange}
          label={id}
        >
          <MenuItem value=''>
            <em>None</em>
          </MenuItem>

          {listItem.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectSection;
