import React from 'react';

interface ITapPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = ({ children, index, value, ...other }: ITapPanelProps) => {
  return (
    <div role='tabpanel' hidden={value !== index} {...other}>
      {value === index && <>{children}</>}
    </div>
  );
};

export default TabPanel;
