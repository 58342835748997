import React from 'react';
import { Box, CircularProgress } from '@mui/material';

interface ILoadingProps {
  children?: React.ReactNode;
}

const Loading: React.FC<ILoadingProps> = ({ children }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress />
        <p>{children}</p>
      </Box>
    </Box>
  );
};

export default Loading;
