import React from 'react';
import { Container } from '@mui/material';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import FormMenu from '../../components/FormMenu';
import HeaderPage from '../../components/HeaderPage/index';
import { PIATTI } from '../../utils';

const colName = 'menu';

const MenuPage = () => {
  return (
    <Container>
      <HeaderPage marginTop={24}>
        Piatti <RestaurantMenuIcon />
      </HeaderPage>
      <FormMenu doc_name={PIATTI} colName={colName} tipoItem={'PIATTI'} />
    </Container>
  );
};

export default MenuPage;
