import React, { FormEvent } from 'react';
import { TextField } from '@mui/material';

interface ITimeComponent {
  value: string;
  name: string;
  id: string;
  index: number;
  timeChange(event: FormEvent, index: number): void;
}

const TimeComponent = ({ id, index, name, value, timeChange }: ITimeComponent) => {
  return (
    <TextField
      onChange={(e) => {
        timeChange(e, index);
      }}
      id={id}
      name={name}
      label={name === 'open' ? 'Apertura' : 'Chiusura'}
      type='time'
      value={value}
      InputLabelProps={{ shrink: true }}
      inputProps={{ step: 1800 }}
      fullWidth
      sx={{ marginBottom: 1 }}
    />
  );
};

export default TimeComponent;
