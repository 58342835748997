import React, { useEffect } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { auth } from '../../firebase/firebase-config';
import { useAuthState } from 'react-firebase-hooks/auth';

interface IHeaderPage {
  children: React.ReactNode;
  marginTop: number;
}

const HeaderPage: React.FC<IHeaderPage> = ({ children, marginTop, ...other }) => {
  const [user, loading] = useAuthState(auth);
  const { navHeight } = useOutletContext<{ navHeight: number }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      return;
    }
    if (!user) {
      return navigate('/');
    }
  }, [loading, user, navigate]);

  return (
    <Box mt={`${navHeight + 24}px`} mb={3}>
      <Typography variant='h4'>{children}</Typography>
    </Box>
  );
};

export default HeaderPage;
