import React from 'react';
import { auth, db } from '../firebase/firebase-config';
import {
  collection,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  FirestoreError,
  query,
  orderBy,
  deleteDoc,
  DocumentData,
  Query,
} from 'firebase/firestore';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { FirebaseError } from 'firebase-admin';
import { Options } from 'react-firebase-hooks/firestore/dist/firestore/types';
import { useEffect, useMemo, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { IItemMenu } from '../@types/dashboard';

export const DEFAULT_ITEM = {
  archiviato: false,
  description_en: '',
  description_it: '',
  id: '',
  name_en: '',
  name_it: '',
  price: 0,
};

const getMenuData = async (colName: string, docName: string): Promise<IItemMenu[] | null> => {
  try {
    const dataCol = collection(db, colName, docName, 'lista');
    const q = query(dataCol, orderBy('name_it'));
    const docs = await getDocs(q);

    if (docs) {
      const dataList = docs.docs.map((item) => ({ ...item.data(), id: item.id })) as IItemMenu[];
      return dataList;
    }
    return null;
  } catch (error) {
    throw new Error(`getMenuData: ${(error as FirestoreError).message}`);
  }
};

const addItem = async (colName: string, docName: string, item: any) => {
  try {
    const dataCol = collection(db, colName, docName, 'lista');
    await addDoc(dataCol, { ...item });
  } catch (error) {
    throw new Error(`addItem: ${(error as FirestoreError).message}`);
  }
};

const updateItem = async (colName: string, docName: string, docId: string, item: any) => {
  try {
    const docRef = doc(db, colName, docName, 'lista', docId);
    await updateDoc(docRef, { ...item }, {});
  } catch (error) {
    throw new Error(`updateDoc: ${(error as FirestoreError).message}`);
  }
};

const deleteItem = async (colName: string, docName: string, docId: string) => {
  try {
    await deleteDoc(doc(db, colName, docName, 'lista', docId));
  } catch (error) {
    throw new Error(`deleteItem: ${(error as FirestoreError).message}`);
  }
};

export interface IContact {
  id: string;
  dati: string;
}

const logInWithEmailAndPassword = async (email: string, password: string) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential;
  } catch (error) {
    throw new Error(`logInWithEmailAndPassword: ${(error as FirebaseError).message}`);
  }
};

const logOut = async () => {
  await auth.signOut();
};

const getTokenIsExpirated = async () => {
  const token = await auth.currentUser?.getIdTokenResult();
  if (token) {
    const expirationTime = new Date(token.expirationTime);
    console.log('getTokenIsExpirated :>> ', expirationTime);
    return expirationTime < new Date();
  }
  return false;
};

const useDocWithId = (query?: Query<DocumentData> | null | undefined, options?: Options | undefined) => {
  const [docs, setDocs] = useState<DocumentData[] | undefined>([]);
  const [collection, loading, error] = useCollection(query, options);

  useEffect(() => {
    if (collection && !collection?.empty) {
      const list = collection.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setDocs(list);
    }
  }, [collection]);

  type DocumentDataHook<T = DocumentData[]> = [T | undefined, boolean, FirestoreError | undefined];

  const resArray: DocumentDataHook = [docs, loading, error];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => resArray, resArray);
};

export const useFocus = () => {
  const ref: React.RefObject<HTMLInputElement> = React.createRef();
  const setFocus = () => {
    ref.current && ref.current?.focus();
  };
  return { setFocus, ref };
};

const VINI = ['bianchi', 'bollicine', 'rossi'];
const PIATTI = ['antipasti', 'primi', 'secondi', 'dessert'];

export {
  getMenuData,
  addItem,
  updateItem,
  deleteItem,
  logInWithEmailAndPassword,
  logOut,
  getTokenIsExpirated,
  useDocWithId,
  VINI,
  PIATTI,
};
