import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Box } from '@mui/material';
import Lottie from 'lottie-react';
import animationData from '../../assets/animation/error-404.json';

const defaultOption = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  style: {
    width: 380,
    height: 380,
  },
};
const ErrorPage = () => {
  return (
    <Container maxWidth='sm'>
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
        width='100%'
        height={'100vh'}
      >
        <Lottie {...defaultOption} />

        <Button variant='outlined' component={Link} to={'/'}>
          Torna alla Home
        </Button>
      </Box>
    </Container>
  );
};

export default ErrorPage;
