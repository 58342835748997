import React, { useState } from 'react';
import { Alert, AlertTitle, Button, Container, Grid, TextField } from '@mui/material';
import HeaderPage from '../../components/HeaderPage';
import KeyIcon from '@mui/icons-material/Key';
import { useUpdatePassword } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase/firebase-config';
import Loading from '../../components/Loading';

const PasswordPage = () => {
  const [password, setPassword] = useState('');
  const [updatePassword, updating, error] = useUpdatePassword(auth);

  return (
    <Container>
      <HeaderPage marginTop={24}>
        Password <KeyIcon />
      </HeaderPage>
      {error && (
        <Alert severity='error'>
          <AlertTitle>Error</AlertTitle>
          {error.message}
        </Alert>
      )}
      {updating && <Loading>Updating...</Loading>}
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} md={6}>
          <TextField
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            type='password'
            name='password'
            label='Password :'
            variant='standard'
            fullWidth
            InputProps={{ inputProps: {} }}
            required
          />
        </Grid>
        <Grid item sm={12}>
          <Button
            variant='outlined'
            color='success'
            onClick={async () => {
              await updatePassword(password);
            }}
            disabled={!(password.length >= 6)}
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PasswordPage;
