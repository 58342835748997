import React from 'react';
import { Alert, AlertTitle } from '@mui/material';

interface IError {
  title: string;
  errorMsg: string;
}

const Error: React.FC<IError> = ({ title, errorMsg }) => {
  return (
    <Alert severity='error'>
      <AlertTitle>{title}</AlertTitle>
      {errorMsg}
    </Alert>
  );
};

export default Error;
