import HomePage from './Home';
import ErrorPage from './Error';
import LoginPage from './Login/index';
import OrariPage from './orari/index';
import MenuPage from './menu/index';
import DeletePage from './Delete';
import ContactsPage from './Contacts';
import HomeIcon from '@mui/icons-material/Home';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import CollectionsIcon from '@mui/icons-material/Collections';
import WineBarIcon from '@mui/icons-material/WineBar';
import ViniPage from './Vini/index';
import DeleteIcon from '@mui/icons-material/Delete';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';

const pages = [
  {
    path: 'home',
    icon: <HomeIcon />,
  },
  {
    path: 'contatti',
    icon: <PermContactCalendarIcon />,
  },
  {
    path: 'orari',
    icon: <AccessTimeIcon />,
  },
  {
    path: 'menu',
    icon: <RestaurantMenuIcon />,
  },
  {
    path: 'vini',
    icon: <WineBarIcon />,
  },
  {
    path: 'gallery',
    icon: <CollectionsIcon />,
  },
  {
    path: 'delete',
    icon: <DeleteIcon />,
  },
];

export { pages, HomePage, ErrorPage, LoginPage, OrariPage, MenuPage, ViniPage, DeletePage, ContactsPage };
