import { Box, Popover, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { ITableFilter } from '../../@types/dashboard';
import SelectSection from '../SelectSection/index';

const TableFilter: React.FC<ITableFilter> = ({ anchorEl, filterString, filter, setFilter, setAnchorEl }) => {
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setFilter(event.target.value);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'filter' : undefined;

  return (
    <Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <SelectSection label='Cartella' handleChange={handleChange} listItem={filterString} selected={filter} />
      </Popover>
    </Box>
  );
};

export default TableFilter;
