import { Box, Grid, Tab, Tabs, Divider, Button, Alert, AlertTitle, CircularProgress, Snackbar } from '@mui/material';
import React, { useState, useEffect, FormEvent } from 'react';
import { db } from '../../firebase/firebase-config';
import {
  collection,
  doc,
  DocumentData,
  DocumentReference,
  QueryDocumentSnapshot,
  SnapshotOptions,
  updateDoc,
} from 'firebase/firestore';
import { Container } from '@mui/system';
import TabPanel from '../../components/TabPanel';
import TimeComponent from '../../components/Time/index';
import HeaderPage from '../../components/HeaderPage/index';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useCollectionData } from 'react-firebase-hooks/firestore';

export interface IOrari {
  day: string;
  id: string;
  ref: DocumentReference<DocumentData>;
  openingTime: { closed: string; open: string }[];
}

const timeConverter = {
  toFirestore({ openingTime }: IOrari): DocumentData {
    return { openingTime };
  },
  fromFirestore(time: QueryDocumentSnapshot, options: SnapshotOptions): IOrari {
    const { id, ref } = time;
    const { day, openingTime } = time.data(options)!;
    return {
      day,
      id,
      ref,
      openingTime,
    };
  },
};

const OrariPage = () => {
  const [orari, setOrari] = useState<IOrari[]>([]);
  const [panel, setPanel] = useState(0);
  const [isSaveSuccess, setIsSaveSuccess] = useState(false);
  const timeColName = 'time';
  const [orariCol, isLoading, isError] = useCollectionData(collection(db, timeColName).withConverter(timeConverter));

  useEffect(() => {
    if (orariCol) {
      setOrari(orariCol);
    }
  }, [orariCol]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setPanel(newValue);
  };

  const timeChange = (event: FormEvent, index: number) => {
    const { name, id, value } = event.target as HTMLInputElement;
    setOrari((prevOrari) =>
      prevOrari.map((item) =>
        item.id === id
          ? {
              ...item,
              openingTime: [
                ...item.openingTime.map((subItem, i) => (i === index ? { ...subItem, [name]: value } : { ...subItem })),
              ],
            }
          : { ...item }
      )
    );
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (orari) {
      const { id, openingTime } = orari[panel];
      const dayRef = doc(db, timeColName, id).withConverter(timeConverter);
      try {
        await updateDoc(dayRef, { openingTime });
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  return (
    <Container>
      <HeaderPage marginTop={24}>
        Orari <AccessTimeIcon />
      </HeaderPage>

      <Snackbar open={isSaveSuccess} autoHideDuration={2000} onClose={() => setIsSaveSuccess(false)}>
        <Alert onClose={() => setIsSaveSuccess(false)} severity='success' sx={{ width: '100%' }}>
          Orario salvato.
        </Alert>
      </Snackbar>

      {isError ? (
        <Alert severity='error'>
          <AlertTitle>Error</AlertTitle>
          {JSON.stringify(isError)}
        </Alert>
      ) : isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box onSubmit={handleSubmit} component='form' sx={{ display: 'grid', gridTemplateColumns: '1fr 2fr' }}>
          <Tabs
            orientation='vertical'
            onChange={handleChange}
            value={panel}
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            {orari?.map(({ day }, i) => (
              <Tab key={day} label={day} />
            ))}
          </Tabs>
          <div>
            {orari?.map(({ openingTime, id }, i) => (
              <TabPanel key={id} value={panel} index={i}>
                <Grid container spacing={2} mt={1} p={1}>
                  {openingTime.map(({ open, closed }, i) => (
                    <Grid key={i} item xs={12}>
                      <TimeComponent id={id} index={i} timeChange={timeChange} name='open' value={open} />
                      <TimeComponent id={id} index={i} timeChange={timeChange} name='closed' value={closed} />
                      {i < openingTime.length - 1 && <Divider sx={{ margin: 2 }} />}
                    </Grid>
                  ))}
                </Grid>
              </TabPanel>
            ))}
            <Box component={'div'} p={1} display='flex' justifyContent='center'>
              <Button type='submit' variant='outlined' color='success' sx={{ minWidth: '200px' }}>
                salva
              </Button>
            </Box>
          </div>
        </Box>
      )}
    </Container>
  );
};

export default OrariPage;
