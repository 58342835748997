import { Container, Card, CardContent, Typography, Grid } from '@mui/material';
import React from 'react';
import HeaderPage from '../../components/HeaderPage';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';

import ListContact from '../../components/ListContact';

const ContactsPage = () => {
  return (
    <Container>
      <HeaderPage marginTop={24}>
        Contatti <PermContactCalendarIcon />
      </HeaderPage>
      {
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Card variant='outlined'>
              <CardContent>
                <Typography gutterBottom variant='h5' component='div'>
                  Cellulare
                </Typography>
                <ListContact contact='cellulare' />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Card variant='outlined'>
              <CardContent>
                <Typography gutterBottom variant='h5' component='div'>
                  Email
                </Typography>
                <ListContact contact='email' />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Card variant='outlined'>
              <CardContent>
                <Typography gutterBottom variant='h5' component='div'>
                  Fax
                </Typography>
                <ListContact contact='fax' />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Card variant='outlined'>
              <CardContent>
                <Typography gutterBottom variant='h5' component='div'>
                  Telefono
                </Typography>
                <ListContact contact='telefono' />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Card variant='outlined'>
              <CardContent>
                <Typography gutterBottom variant='h5' component='div'>
                  Indirizzo
                </Typography>
                <ListContact contact='indirizzo' addButton={false} deleteButton={false} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      }
    </Container>
  );
};

export default ContactsPage;
