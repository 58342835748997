import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './Router/index';
import './App.css';
import { ThemeContext } from './utils/theme';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import AppProvaider from './context';

const getFromLocalStorage = (): 'dark' | 'light' => {
  const theme = localStorage.getItem('theme') as 'dark' | 'light';

  if (theme) {
    return theme;
  }
  return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
};

function App() {
  const [mode, setMode] = useState<'light' | 'dark'>(getFromLocalStorage());
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    []
  );

  useEffect(() => {
    localStorage.setItem('theme', mode);
  }, [mode]);

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === 'dark' && {
            background: {
              default: '#212121',
              paper: '#212121',
            },
          }),
        },
      }),
    [mode]
  );

  return (
    <AppProvaider>
      <ThemeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </ThemeProvider>
      </ThemeContext.Provider>
    </AppProvaider>
  );
}

export default App;
