import {
  List,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  ListItem,
  ListItemText,
  Box,
  CircularProgress,
  Alert,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { IItemMenu } from '../../@types/dashboard';
import { deleteItem, getMenuData } from '../../utils/index';
import ConfirmDialog from '../ConfirmDialog';
import Error from '../Error';

interface IDeleteItem extends IItemMenu {
  checked: boolean;
}

interface IListItemMenu {
  colName: string;
  docName: string;
  setSelectItem(value: string): void;
  setIsDeletedSucces(success: boolean): void;
}

const ListItemMenu: React.FC<IListItemMenu> = ({ colName, docName, setSelectItem, setIsDeletedSucces }) => {
  const [items, setItems] = useState<IDeleteItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState({ state: false, msg: '' });

  const getData = async () => {
    setIsLoading(true);
    setIsError({ state: false, msg: '' });
    try {
      const list = await getMenuData(colName, docName);
      if (list !== null) {
        setItems(list.map((item) => ({ ...item, checked: false })));
      }
    } catch (error) {
      const { message } = error as Error;
      setIsError({ state: true, msg: message });
    }
    setIsLoading(false);
  };

  const handleToggle = (index: number) => () => {
    setItems(items.map((item, i) => ({ ...item, checked: i === index ? !item.checked : item.checked })));
    setTimeout(() => {}, 2000);
  };

  const handleDelete = () => {
    items.map(async ({ checked, id }) => (checked ? await deleteItem(colName, docName, id) : null));
    setIsDeletedSucces(true);
    setSelectItem('');
  };

  useEffect(() => {
    (async () => {
      await getData();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colName, docName]);

  return (
    <Box mt={5} mb={5}>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : isError.state ? (
        <Error title='Error' errorMsg={isError.msg} />
      ) : items.length === 0 ? (
        <Alert severity='warning'>Non sono presenti {`${colName.toUpperCase()} ${docName.toLocaleUpperCase()}`}</Alert>
      ) : (
        <>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {items.map(({ id, name_it, checked }, i) => (
              <ListItem key={id} disablePadding>
                <ListItemButton role={undefined} onClick={handleToggle(i)} dense>
                  <ListItemIcon>
                    <Checkbox
                      edge='start'
                      checked={checked}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': id }}
                    />
                  </ListItemIcon>
                  <ListItemText id={id} primary={name_it} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Box component='div' sx={{ width: { lg: '25%' } }}>
            <ConfirmDialog
              buttonText='elimina'
              contentText='Vuoi eliminare i dati selezionati?'
              disabled={!!!~items.findIndex(({ checked }) => checked)}
              title='Elimina'
              size='large'
              color='error'
              onConfirm={() => handleDelete()}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default ListItemMenu;
