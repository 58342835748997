import React from 'react';
import { Container } from '@mui/material';
import FormMenu from '../../components/FormMenu';
import WineBarIcon from '@mui/icons-material/WineBar';
import HeaderPage from '../../components/HeaderPage/index';
import { VINI } from '../../utils';

const colName = 'vini';

const ViniPage = () => {
  return (
    <Container>
      <HeaderPage marginTop={24}>
        Vini <WineBarIcon />
      </HeaderPage>

      <FormMenu doc_name={VINI} colName={colName} tipoItem={'PIATTI'} />
    </Container>
  );
};

export default ViniPage;
