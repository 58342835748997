import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  CircularProgress,
  SelectChangeEvent,
  AlertTitle,
} from '@mui/material';
import {
  collection,
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from 'firebase/firestore';
import React, { useEffect } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { IItemMenu } from '../../@types/dashboard';
import { db } from '../../firebase/firebase-config';

interface ISelectItemMenuProps {
  colName: string;
  docName: string;
  setItemData: React.Dispatch<React.SetStateAction<IItemMenu>>;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  selected: string;
}

const valueConverter: FirestoreDataConverter<IItemMenu> = {
  toFirestore({ archiviato, description_en, description_it, name_en, name_it, price }: IItemMenu): DocumentData {
    return { archiviato, description_en, description_it, name_en, name_it, price };
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): IItemMenu {
    const { id } = snapshot;
    const { archiviato, description_en, description_it, name_en, name_it, price } = snapshot.data(options)!;
    return {
      archiviato,
      description_en,
      description_it,
      name_en,
      name_it,
      price: price / 10,
      id,
    };
  },
};

const SelectItemMenu: React.FC<ISelectItemMenuProps> = ({
  colName,
  docName,
  setItemData,
  setSelected,
  setIsLoading,
  selected,
}) => {
  const tipoItem = colName === 'menu' ? 'PIATTI' : colName;
  const [value, loading, error] = useCollectionData(
    collection(db, colName, docName, 'lista').withConverter(valueConverter)
  );

  const handleChange = (event: SelectChangeEvent) => {
    const index = event.target.value;
    setSelected(index);
    if (value) setItemData(value[parseInt(index)]);
  };

  useEffect(() => {
    setIsLoading(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (!value) return <></>;
  return (
    <>
      {error ? (
        <Alert severity='error'>
          <AlertTitle>Error</AlertTitle>
          {JSON.stringify(error)}
        </Alert>
      ) : loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : value.length === 0 ? (
        <Alert severity='warning'>Non sono presenti {tipoItem.toUpperCase()}</Alert>
      ) : (
        <FormControl fullWidth>
          <InputLabel id='select-item'>{tipoItem}</InputLabel>
          <Select
            labelId='select-item'
            id='demo-simple-select'
            value={selected}
            label={tipoItem}
            onChange={handleChange}
          >
            {value.map(({ id, name_it }, i) => (
              <MenuItem key={id} value={i}>
                {name_it}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default SelectItemMenu;
