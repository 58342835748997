import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button } from '@mui/material';
import React, { useState, useEffect, ChangeEvent } from 'react';
import { IContact } from '../../utils/index';
import validator from 'validator';

interface IDialogComponent {
  content?: string;
  id?: string;
  dati?: string;
  handleClose(): void;
  handleSaveData(id: string, dati: string): void;
  open: boolean;
  title: string;
  type: 'cellulare' | 'email' | 'fax' | 'telefono' | 'indirizzo';
}

const INPUT_TYPE = { cellulare: 'tel', email: 'email', fax: 'tel', telefono: 'tel', indirizzo: 'text' };

const ContactDialog: React.FC<IDialogComponent> = ({
  content,
  handleClose,
  handleSaveData,
  open,
  title,
  id,
  dati,
  type,
}) => {
  const [data, setData] = useState<IContact>({ id: '', dati: '' });
  const [isValidContact, setIsValidContact] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    setData({ ...data, dati: value });
    type === 'cellulare' && setIsValidContact(validator.isMobilePhone(value, 'it-IT'));
    (type === 'fax' || type === 'telefono') && setIsValidContact(validator.isInt(value) && value.length === 10);
    type === 'email' && setIsValidContact(validator.isEmail(value));
    type === 'indirizzo' && setIsValidContact(validator.isAlphanumeric(value, 'it-IT', { ignore: ' -,.()' }));
  };

  useEffect(() => {
    setIsValidContact(false);
    setData({ id: id || '', dati: dati || '' });
  }, [id, dati, open]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
        <TextField
          autoFocus
          margin='dense'
          type={INPUT_TYPE[type]}
          fullWidth
          variant='standard'
          value={data.dati}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Annulla</Button>
        <Button
          onClick={(e) => {
            handleSaveData(data.id, data.dati);
            handleClose();
          }}
          disabled={!isValidContact}
        >
          {id ? 'Aggiorna' : 'Aggiungi'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactDialog;
